<template>
  <component
    :is="tab"
    :key="index"
    v-bind="tab.props"
    :ref="`tab-${index}`"
    @toggle="onTabToggle"
    :accordionItemIndex="index"
    v-for="(tab, index) in tabs"
  />
</template>

<script>

  export default {
    props: {
      allowMultiple: {
        type: Boolean,
        default: true,
      },
    },
    computed: {
      tabs() {

        const tabs = []

        this.$slots.default().forEach((slot) => {
          if (slot.type.name !== 'AccordionItem') return
          tabs.push(slot)
        })

        return tabs

      },
    },
    methods: {
      onTabToggle({ isOpen, accordionItemIndex }) {
        if (!isOpen || this.allowMultiple) return
        this.tabs.forEach((tab, index) => {
          if (index === accordionItemIndex) return
          this.$refs[`tab-${index}`].close()
        })
      },
    },
  }

</script>

<style lang="stylus" scoped>

</style>
