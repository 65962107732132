<template>

  <form @submit.prevent>

    <div id="compare" class="offset-anchor" />

    <h2>Dispatch plans</h2>

    <section class="plans">

      <!-- @NOTE: yearly plans are currently disabled -->
      <!--
        <FormRadio
        :showLabel="false"
        :showError="false"
        :formName="formName"
        fieldName="payPeriod"
        type="content-switcher"
        class="pay-period-switcher"
      /> -->

      <div class="plan-columns">
        <div class="plan-wrapper bordered">
          <div class="plan">
            <img class="plan-image" :src="stripePlansSlugMap.free.images[0]" />
            <h5>{{ stripePlansSlugMap.free.name }}</h5>
            <p>{{ stripePlansSlugMap.free.description }}</p>
            <div class="plan-price">
              <span class="cost">$0</span>
              <span class="pay-period">forever</span>
              <!-- @NOTE: yearly plans are currently disabled -->
              <!-- <p class="description">
                No credit card required
                <br>
                &nbsp;
              </p> -->
              <button
                type="button"
                class="small outline"
                @click="selectPlan(stripePricesSlugMap.free)"
                :disabled="isLoading || userPlan.slug === 'free' || willSubscriptionCancelIntentionally"
              >
                <template v-if="userPlan.slug === 'free'">
                  Current plan <CheckCircleIcon class="current-plan-icon" />
                </template>
                <template v-else-if="willSubscriptionCancelIntentionally">
                  Downgrade scheduled
                </template>
                <template v-else>
                  Select plan
                </template>
              </button>
            </div>
            <div class="plan-features">
              <strong>{{ stripePlansSlugMap.free.name }} includes:</strong>
              <ul class="checked-list">
                <li>{{ formatMaxEventsMonthly(stripePlansSlugMap.free) }} Actions</li>
                <li>{{ formatMaxEventsTimeframe(stripePlansSlugMap.free, true) }} event history</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="plan-wrapper bordered">
          <div class="plan">
            <img class="plan-image" :src="stripePlansSlugMap.starter.images[0]" />
            <h5>{{ stripePlansSlugMap.starter.name }}</h5>
            <p>{{ stripePlansSlugMap.starter.description }}</p>
            <div class="plan-price">
              <template v-if="selectedPayPeriod === 'monthly'">
                <span class="cost">{{ costsPerMonth.starter.monthly }}</span>
                <span class="pay-period">/ month</span>
                <!-- @NOTE: yearly plans are currently disabled -->
                <!-- <p class="description">
                  Billed monthly
                  <br>
                  or {{ costsPerMonth.starter.yearly }}/mo for an annual plan
                </p> -->
                <button
                  type="button"
                  class="small outline"
                  @click="selectPlan(stripePricesSlugMap.starter.monthly)"
                  :disabled="isLoading || (userPlan.slug === 'starter' && userPlan.billingInterval === 'month')"
                >
                  <template v-if="userPlan.slug === 'starter' && userPlan.billingInterval === 'month'">
                    Current plan <CheckCircleIcon class="current-plan-icon" />
                  </template>
                  <template v-else>
                    Select plan
                  </template>
                </button>
              </template>
              <template v-else>
                <span class="cost">{{ costsPerMonth.starter.yearly }}</span>
                <span class="pay-period">/ month</span>
                <p class="description">
                  {{ formatNumberAsUSD(stripePricesSlugMap.starter.yearly.unitAmount / 100) }} billed annually
                  <br>
                  or {{ costsPerMonth.starter.monthly }}/mo for a monthly plan
                </p>
                <button
                  type="button"
                  class="small outline"
                  @click="selectPlan(stripePricesSlugMap.starter.yearly)"
                  :disabled="isLoading || (userPlan.slug === 'starter' && userPlan.billingInterval === 'year')"
                >
                  <template v-if="userPlan.slug === 'starter' && userPlan.billingInterval === 'year'">
                    Current plan <CheckCircleIcon class="current-plan-icon" />
                  </template>
                  <template v-else>
                    Select plan
                  </template>
                </button>
              </template>
            </div>
            <div class="plan-features">
              <strong>{{ stripePlansSlugMap.starter.name }} includes:</strong>
              <ul class="checked-list">
                <li>{{ formatMaxEventsMonthly(stripePlansSlugMap.starter) }} Actions</li>
                <li>{{ formatMaxEventsTimeframe(stripePlansSlugMap.starter, true) }} event history</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="plan-wrapper bordered most-popular">
          <div class="most-popular-label">
            <SparklesIcon />
            Most popular
          </div>
          <div class="plan">
            <img class="plan-image" :src="stripePlansSlugMap.pro.images[0]" />
            <h5>{{ stripePlansSlugMap.pro.name }}</h5>
            <p>{{ stripePlansSlugMap.pro.description }}</p>
            <div class="plan-price">
              <template v-if="selectedPayPeriod === 'monthly'">
                <span class="cost">{{ costsPerMonth.pro.monthly }}</span>
                <span class="pay-period">/ month</span>
                <!-- @NOTE: yearly plans are currently disabled -->
                <!-- <p class="description">
                  Billed monthly
                  <br>
                  or {{ costsPerMonth.pro.yearly }}/mo for an annual plan
                </p> -->
                <button
                  type="button"
                  class="small"
                  @click="selectPlan(stripePricesSlugMap.pro.monthly)"
                  :disabled="isLoading || (userPlan.slug === 'pro' && userPlan.billingInterval === 'month')"
                >
                  <template v-if="userPlan.slug === 'pro' && userPlan.billingInterval === 'month'">
                    Current plan <CheckCircleIcon class="current-plan-icon" />
                  </template>
                  <template v-else>
                    Select plan
                  </template>
                </button>
              </template>
              <template v-else>
                <span class="cost">{{ costsPerMonth.pro.yearly }}</span>
                <span class="pay-period">/ month</span>
                <p class="description">
                  {{ formatNumberAsUSD(stripePricesSlugMap.pro.yearly.unitAmount / 100) }} billed annually
                  <br>
                  or {{ costsPerMonth.pro.monthly }}/mo for a monthly plan
                </p>
                <button
                  type="button"
                  class="small"
                  @click="selectPlan(stripePricesSlugMap.pro.yearly)"
                  :disabled="isLoading || (userPlan.slug === 'pro' && userPlan.billingInterval === 'year')"
                >
                  <template v-if="userPlan.slug === 'pro' && userPlan.billingInterval === 'year'">
                    Current plan <CheckCircleIcon class="current-plan-icon" />
                  </template>
                  <template v-else>
                    Select plan
                  </template>
                </button>
              </template>
            </div>
            <div class="plan-features">
              <strong>{{ stripePlansSlugMap.pro.name }} includes:</strong>
              <ul class="checked-list primary">
                <li>{{ formatMaxEventsMonthly(stripePlansSlugMap.pro) }} Actions</li>
                <li>{{ formatMaxEventsTimeframe(stripePlansSlugMap.pro) }} event history</li>
                <li>Priority support</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

    </section>

    <section class="add-ons">
      <div id="pay-per-action" class="offset-anchor" />
      <div class="pay-per-action bordered">
        <div>
          <span class="badge subtle purple">ADD-ON</span>
          <span class="ml-2 italic text-gray-600">For paid subscriptions only</span>
        </div>
        <div class="main-content">
          <div class="copy">
            <h5>Pay per Action</h5>
            <p>
              With Pay per Action enabled, your Patches stay on even when you
              hit your monthly usage limit, ensuring you never miss crucial
              activity.
            </p>
            <p>
              Actions over your usage limit will be charged at a rate of $0.02
              per Action.
            </p>
            <p>
              Check your usage <span v-if="isPPAEnabled">and estimated overage charges</span> in
              <router-link class="black" to="/account/usage-and-billing">Usage and billing</router-link>
            </p>
          </div>
          <div class="toggle">
            <template v-if="isOnFreePlan">
              <Tooltip>
                <template #trigger>
                  <FormCheckbox
                    @click.prevent
                    :isToggle="true"
                    toggleSize="large"
                    :showLabel="false"
                    :showError="false"
                    :formName="formName"
                    fieldName="isPayPerActionEnabled"
                  />
                </template>
                <div class="mb-3 text-lg">
                  <strong>Upgrade to add 👆</strong>
                </div>
                <p>Pay per Action is only available for paid subscription plans.</p>
              </Tooltip>
            </template>
            <template v-else>
              <FormCheckbox
                :isToggle="true"
                toggleSize="large"
                :showLabel="false"
                :showError="false"
                :formName="formName"
                fieldName="isPayPerActionEnabled"
                @click.prevent="openPayPerActionConfirmationModal"
              />
            </template>
          </div>
        </div>
      </div>
      <div class="enterprise bordered">
        <div class="image">
          <img class="plan-image" src="@/assets/images/plans/planet.png" />
        </div>
        <div class="copy">
          <h5>Enterprise</h5>
          <p class="muted">For organizations needing advanced or higher volume automation to run business operations</p>
          <p>Schedule time with our sales team to explore custom packages to fit your needs.</p>
          <button class="small" @click="openHelpscoutBeacon('Question about Dispatch Enterprise Plan')">Request a call</button>
        </div>
      </div>
    </section>

    <section class="faqs">
      <div id="faq" class="offset-anchor" />
      <div id="faqs" class="offset-anchor" />
      <h3>FAQ</h3>
      <Accordion>
        <AccordionItem title="What are Actions?" @opened="faqToggled('What are Actions?')">
          <p>
            An Action is what happens every time your Patch Trigger conditions
            are met.
          </p>
          <p>
            Examples of Actions include:
            <ul>
              <li>Sending Telegram messages</li>
              <li>Posting in a Discord server channel</li>
              <li>Sending a JSON payload to a <a target="_blank" href="https://docs.esprezzo.io/webhooks/webhooks-overview">webhook</a> URL</li>
              <li>Sending an email</li>
              <li>Logging an event to <router-link to="/monitor">Dispatch Monitor</router-link></li>
            </ul>
          </p>
        </AccordionItem>
        <AccordionItem title="What counts toward my plan limits?" @opened="faqToggled('What counts toward my plan limits?')">
          <p>
            An Action counts toward your plan limit every time Dispatch
            successfully delivers data or automatically completes a task in
            response to your Trigger conditions being met.
          </p>
          <p>
            For example, if your Patch’s Action is to post in a Discord server
            channel when a smart contract emits a certain event, each message
            Dispatch posts in your channel counts as one Action.
          </p>
          <p>
            Monthly Action limits, by plan:
            <ul>
              <li><strong>{{ stripePlansSlugMap.free.name }}:</strong> {{ formatMaxEventsMonthly(stripePlansSlugMap.free) }} Actions</li>
              <li><strong>{{ stripePlansSlugMap.starter.name }}:</strong> {{ formatMaxEventsMonthly(stripePlansSlugMap.starter) }} Actions</li>
              <li><strong>{{ stripePlansSlugMap.pro.name }}:</strong> {{ formatMaxEventsMonthly(stripePlansSlugMap.pro) }} Actions</li>
            </ul>
          </p>
          <p>
            Other than Actions, your plan also determines how much event history
            you can view.
          </p>
          <p>
            The Free plan allows you to view the last
            {{ formatMaxEventsTimeframe(stripePlansSlugMap.free) }} of
            Patch activity. If you want to be able to view smart contract and
            wallet activity for longer periods of time (more historical
            activity), the Starter or Pro plans might be better options for you.
          </p>
          <p>
            Event history, by plan:
            <ul>
              <li><strong>{{ stripePlansSlugMap.free.name }}:</strong> {{ formatMaxEventsTimeframe(stripePlansSlugMap.free) }}</li>
              <li><strong>{{ stripePlansSlugMap.starter.name }}:</strong> {{ formatMaxEventsTimeframe(stripePlansSlugMap.starter) }}</li>
              <li><strong>{{ stripePlansSlugMap.pro.name }}:</strong> {{ formatMaxEventsTimeframe(stripePlansSlugMap.pro) }}</li>
            </ul>
          </p>
        </AccordionItem>
        <AccordionItem title="What happens if I hit my plan limits?" @opened="faqToggled('What happens if I hit my plan limits?')">
          <p>
            We’ll email you when you’re nearing your plan’s monthly Action
            limit.
          </p>
          <p>
            Once Dispatch has sent the maximum number of Actions allowed by your
            plan, all your Patches will be paused. They won’t be deleted, just
            paused, until the next billing cycle starts. We’ll send you an email
            to let you know if this happens. You can still create new Patches,
            but you won’t be able to turn them on until the next billing cycle
            starts.
          </p>
          <p>
            If you receive a notification that your Patches are paused due to
            hitting your Action limit, you can upgrade at any time, to turn your
            Patches back on. However, you won’t be able to recover any events
            that were missed when your Patches were paused.
          </p>
          <p>
            If you’re on the Starter or Pro plans, you have the option to
            activate the Pay per Action add-on. When Pay per Action is
            activated, your Patches will continue running uninterrupted after
            you hit your Action limit. Any Actions over your plan limit will be
            billed at $0.02 per Action, and will be automatically charged after
            your billing cycle ends.
          </p>
        </AccordionItem>
        <AccordionItem title="Is there a free plan available?" @opened="faqToggled('Is there a free plan available?')">
          <p>
            Yes, we offer a Free plan that allows you to get started with
            Dispatch, without a credit card.
          </p>
          <p>
            In fact, all Dispatch accounts start out on the Free plan.
          </p>
          <p>
            The Free plan does come with some limitations:
            <ul>
              <li>Actions: {{ formatMaxEventsMonthly(stripePlansSlugMap.free) }} per month</li>
              <li>Event history: Last {{ formatMaxEventsTimeframe(stripePlansSlugMap.free) }}</li>
            </ul>
          </p>
          <p>
            If you are working with or monitoring very active contracts or
            wallets, or need access to more historical data, we recommend the
            Starter or Pro plans.
          </p>
          <p>
            Pay per Action – an Add-On that gives you unlimited Actions (you’ll
            pay a per-Action fee for every Action over your monthly plan limit)
            — is only available for Starter or Pro plans.
          </p>
        </AccordionItem>
        <AccordionItem title="Can I upgrade my plan at any time?" @opened="faqToggled('Can I upgrade my plan at any time?')">
          <p>
            Yes, you can upgrade at any time.
          </p>
          <p>
            If you upgrade, your plan will be updated immediately, with more
            Actions and more event history.
          </p>
          <p>
            Our plans are prorated, which means when you upgrade mid-billing
            cycle, you pay the prorated portion of the higher priced plan.
          </p>
          <p>
            The amount you’ll pay on the day you upgrade is calculated as
            follows:
            <br>
            (<pre># of days remaining in billing cycle</pre> x <pre>daily cost
            of new plan</pre>) - (<pre># of days elapsed in current billing
            cycle</pre> x <pre>daily cost of old plan</pre>)
          </p>
        </AccordionItem>
        <AccordionItem title="Can I downgrade or cancel my plan at any time?" @opened="faqToggled('Can I downgrade or cancel my plan at any time?')">
          <p>
            Yes, you can downgrade or cancel at any time.
          </p>
          <div class="subtitle">Downgrading to Free</div>
          <p>
            <strong>Downgrading to the Free plan</strong> cancels your
            subscription at the end of the current billing cycle.
          </p>
          <p>
            If you have activated Pay per Action, Pay per Action will also be
            deactivated at the end of your billing cycle when your plan
            downgrades to the Free plan, so you’ll be limited to
            {{ formatMaxEventsMonthly(stripePlansSlugMap.free) }} monthly
            Actions.
          </p>
          <div class="subtitle">Downgrading from Pro to Starter</div>
          <p>
            If you <strong>downgrade from Pro to Starter</strong>, you’ll lose
            access to all Pro plan benefits <strong>immediately</strong>.
          </p>
          <p>
            Your Action limit will immediately change from {{
            formatMaxEventsMonthly(stripePlansSlugMap.pro) }} Actions per month,
            to {{ formatMaxEventsMonthly(stripePlansSlugMap.starter) }} Actions
            per month.
          </p>
          <p class="alert warning">
            If you're already over
            {{ formatMaxEventsMonthly(stripePlansSlugMap.starter) }} Actions
            when you downgrade, your
            <strong>Patches will pause immediately</strong>, and you won't be
            able to activate any of your Patches until your next usage cycle
            starts.
          </p>
          <p>
            When you downgrade from Pro to Starter, your viewable Patch history
            will immediately be reduced from the last
            {{ formatMaxEventsTimeframe(stripePlansSlugMap.pro) }}, to the
            last {{ formatMaxEventsTimeframe(stripePlansSlugMap.starter) }}.
          </p>
        </AccordionItem>
        <AccordionItem title="What forms of payment do you accept?" @opened="faqToggled('What forms of payment do you accept?')">
          <p>
            You can pay with:
            <ul>
              <li>Credit &amp; debit cards</li>
              <li>Apple Pay</li>
              <li>Google Pay</li>
              <li>Cash App</li>
            </ul>
          </p>
        </AccordionItem>
        <AccordionItem title="Can I pay in my local currency?" @opened="faqToggled('Can I pay in my local currency?')">
          <p>
            All transactions are processed in US dollars (USD). When you reach
            the checkout page, you'll see the total amount displayed in USD.
          </p>
        </AccordionItem>
        <AccordionItem title="Can I pay with crypto?" @opened="faqToggled('Can I pay with crypto?')">
          <p>
            At this time, we don’t accept crypto payments for Dispatch.
          </p>
          <p>
            Currently, we accept:
            <ul>
              <li>Credit &amp; debit cards</li>
              <li>Apple Pay</li>
              <li>Google Pay</li>
              <li>Cash App</li>
            </ul>
          </p>
        </AccordionItem>
        <AccordionItem title="When does my billing cycle start?" @opened="faqToggled('When does my billing cycle start?')">
          <p>
            Your billing cycle starts the day you first upgraded from Free to any paid plan.
          </p>
          <p>
            For example, if you upgraded from Free to the Starter Plan on April
            12, your billing cycle starts on the 12th of every month. If you then
            upgrade from Starter to Pro, your billing cycle still starts on the
            12th of every month, and you’ll pay a prorated (partial) amount for
            the Pro plan, since you’re upgrading mid-billing cycle.
          </p>
          <p>
            If you have the Pay per Action add-on, for months that you go over
            your monthly Action limit, you’ll automatically be charged for
            Actions exceeding your plan limits after your monthly billing cycle
            ends.
          </p>
          <p>
            You can always see when your <strong>usage cycle</strong> restarts
            in your usage meter box from the
            <router-link to="/dashboard">Dashboard</router-link>.
          </p>
          <p>
            You can always see your next <strong>billing date</strong> in
            <router-link to="/account/usage-and-billing">Usage &amp; billing</router-link>.
          </p>
        </AccordionItem>
        <AccordionItem title="What if I need more than any of the plans you offer?" @opened="faqToggled('What if I need more than any of the plans you offer?')">
          <p>
            If you find that none of our current plans meet your needs, we're
            more than happy to work with you to create a customized solution.
          </p>
          <p>
            To discuss a custom plan tailored to your needs, please
            <button class="link" @click="openHelpscoutBeacon('Question about Dispatch Plans')">contact our team</button>.
          </p>
          <p>
            If all you need is more Actions and you’re already on the Starter or
            Pro plan, the Pay per Action add-on may be a good option.
          </p>
          <p>
            Pay per Action allows you to exceed your plan's monthly Action limit
            by paying a per Action fee of $0.02 for each additional Action
            beyond your plan's limit. This can be a cost-effective way to scale
            your usage as needed without committing to a higher-tier plan.
          </p>
        </AccordionItem>
        <AccordionItem title="What is Pay per Action?" @opened="faqToggled('What is Pay per Action?')">
          <p>
            Pay per Action is an add-on to ensure your Patches keep running, and
            you keep receiving on-chain data even if you hit your monthly Action
            limit.
            <ul>
              <li>
                If you have Pay per Action enabled, once you hit your monthly
                Actions limit, you’ll be charged $0.02 per Action over the
                limit.
              </li>
              <li>
                We’ll bill you after your monthly usage cycle ends.
              </li>
            </ul>
          </p>
        </AccordionItem>
        <AccordionItem title="Why can I not turn on Pay per Action?" @opened="faqToggled('Why can I not turn on Pay per Action?')">
          <p>
            Pay per Action is only available if you’re on the Starter or Pro plan.
          </p>
        </AccordionItem>
        <AccordionItem title="What is your refund policy?" @opened="faqToggled('What is your refund policy?')">
          <p>
            If you’d like to discuss a refund request, please
            <button class="link" @click="openHelpscoutBeacon('Question about Dispatch refund')">contact us</button>.
          </p>
        </AccordionItem>
        <AccordionItem title="How do I cancel my subscription?" @opened="faqToggled('How do I cancel my subscription?')">
          <p>
            You can cancel your paid Dispatch plan at any time by changing to
            the Free Plan. If you’re on a paid plan, you’ll still have access to
            everything included in it for the remainder of your billing cycle.
            At the end of your last billing cycle, your account will
            automatically be downgraded to the Free Plan.
          </p>
          <p>
            If you have Pay per Action activated, you can inactivate it anytime
            by switching the toggle for the add-on “Off”. Look for the “Pay per
            Action” <a href="#pay-per-action">add-on box</a> under the pricing table.
          </p>
          <p>
            If you want to delete your account, please
            <button class="link" @click="openHelpscoutBeacon('Question about deleting Dispatch account')">contact us</button>.
            Just know that account deletion is permanent and we won’t be able to
            recover any data once your account is deleted.
          </p>
        </AccordionItem>
        <!-- @NOTE: yearly plans are currently disabled -->
        <!-- <AccordionItem title="Do you offer any discounts?" @opened="faqToggled('Do you offer any discounts?')">
          <p>
            You can save up to 17.5% when you choose an annual plan. This means
            that while you’ll pay a larger amount upfront, you save on what
            you’d spend every month. You can see how much you can save by
            switching between the monthly and yearly pricing options in the
            table above.
          </p>
        </AccordionItem> -->
      </Accordion>
      <div class="questions">
        <h5>I have more questions...</h5>
        <p>No problem! We’re here to help. Click the help widget in the corner and ask away.</p>
      </div>
    </section>

  </form>

</template>

<script>

  import { mapState, mapGetters } from 'vuex'

  import useForm from '@/composables/useForm'
  import useFilters from '@/composables/useFilters'

  import Tooltip from '@/components/utils/Tooltip.vue'

  // import FormRadio from '@/components/forms/FormRadio.vue'
  import FormCheckbox from '@/components/forms/FormCheckbox.vue'

  import Accordion from '@/components/page/Accordion.vue'
  import AccordionItem from '@/components/page/AccordionItem.vue'

  import SparklesIcon from '@/assets/icons/sparkles.svg'
  import CheckCircleIcon from '@/assets/icons/check-circle.svg'

  export default {
    inject: [
      '$mixpanel',
      '$helpscout',
    ],
    components: {
      Tooltip,
      // FormRadio,
      Accordion,
      FormCheckbox,
      SparklesIcon,
      AccordionItem,
      CheckCircleIcon,
    },
    setup() {

      // data
      const formName = 'userPlanForm'

      // composables
      const { form } = useForm({ formName })
      const { formatNumber, formatNumberAsUSD, formatMaxEventsMonthly, formatMaxEventsTimeframe } = useFilters()

      return {
        form,
        formName,
        formatNumber,
        formatNumberAsUSD,
        formatMaxEventsMonthly,
        formatMaxEventsTimeframe,
      }

    },
    computed: {
      ...mapState('user', ['user', 'userPlan']),
      ...mapState('app', ['stripePlansIdMap', 'stripePlansSlugMap', 'stripePricesSlugMap']),
      ...mapGetters('user', ['isPPAEnabled', 'isOnFreePlan', 'willSubscriptionCancelIntentionally']),
      selectedPayPeriod() {
        return this.form.fields.payPeriod.value
      },
      costsPerMonth() {
        return {
          pro: {
            yearly: this.formatNumberAsUSD(this.stripePricesSlugMap.pro.yearly.unitAmount / 1200),
            monthly: this.formatNumberAsUSD(this.stripePricesSlugMap.pro.monthly.unitAmount / 100),
          },
          starter: {
            yearly: this.formatNumberAsUSD(this.stripePricesSlugMap.starter.yearly.unitAmount / 1200),
            monthly: this.formatNumberAsUSD(this.stripePricesSlugMap.starter.monthly.unitAmount / 100),
          },
        }
      },
    },
    data() {
      return {
        isLoading: false,
      }
    },
    created() {
      this.$store.commit('forms/SET_FIELD_VALUE', {
        fieldName: 'isPayPerActionEnabled',
        formName: this.formName,
        newValue: this.isPPAEnabled,
      })
      this.$store.commit('forms/SET_FIELD_DISABLED', {
        fieldName: 'isPayPerActionEnabled',
        formName: this.formName,
        newValue: this.isOnFreePlan,
      })
    },
    methods: {
      faqToggled(faqTitle) {
        this.$mixpanel.onReady((mixpanel) => {
          mixpanel.track('FAQ Opened (Plans Page)', {
            faqTitle,
          })
        })
      },
      openHelpscoutBeacon(subject) {

        this.$mixpanel.onReady((mixpanel) => {
          mixpanel.track('Helpscout Beacon Opened on Plans Page', {
            subject,
          })
        })

        this.$helpscout.onReady((helpscout) => {
          helpscout('prefill', {
            email: this.user.email,
            name: this.user.name,
            subject,
          })
          helpscout('open')
        })

      },
      openPayPerActionConfirmationModal() {
        if (this.isOnFreePlan) return
        this.$store.dispatch('modals/OPEN_MODAL', {
          name: 'PayPerActionConfirmationModal',
          props: {
            newValue: !this.form.fields.isPayPerActionEnabled.value,
          },
        })
      },
      selectPlan(stripePrice) {

        let promise = Promise.resolve()

        const selectedPlan = this.stripePlansIdMap[stripePrice.product]
        const billingPortalFormData = {
          returnUrl: `${process.env.VUE_APP_DISPATCH_URL}${this.$route.path}`,
        }

        if (!selectedPlan) {
          this.$store.dispatch('toast/CREATE_TOAST', {
            text: 'Could not select plan. Please try again later.',
            type: 'error',
          })
          return null
        }

        if (
          this.userPlan.slug === selectedPlan.metadata.productSlug
          && this.userPlan.billingInterval === (selectedPlan.metadata.productSlug === 'free' ? null : stripePrice.recurring.interval)
        ) return null

        const isUpgrade = Number.parseInt(selectedPlan.metadata.planTier, 10) >= this.userPlan.tier

        const mixpanelEventData = {
          selectedPlanName: selectedPlan.name,
          selectedPlanSlug: selectedPlan.metadata.productSlug,

          currentPlanName: this.userPlan.name,
          currentPlanSlug: this.userPlan.slug,

          isUpgrade,
          isDowngrade: !isUpgrade,
          isFirstCheckout: !this.userPlan.stripeSubscription,
          isCancellation: selectedPlan.metadata.productSlug === 'free',
        }

        this.isLoading = true

        // if the user already has a subscription, send them to the Stripe
        //  billing portal with the appropriate flow data
        if (this.userPlan.stripeSubscription) {

          const afterCompletion = {
            type: 'redirect',
            redirect: {
              returnUrl: `${process.env.VUE_APP_DISPATCH_URL}/stripe-subscription-update-return?newStripePriceId=${stripePrice.id}&oldStripePriceId=${this.userPlan.stripePrice.id}`,
            },
          }

          if (selectedPlan.metadata.productSlug === 'free') {
            billingPortalFormData.flowData = {
              type: 'subscription_cancel',
              afterCompletion,
              subscriptionCancel: {
                subscription: this.userPlan.stripeSubscription.id,
              },
            }

          } else {
            billingPortalFormData.flowData = {
              type: 'subscription_update_confirm',
              afterCompletion,
              subscriptionUpdateConfirm: {
                subscription: this.userPlan.stripeSubscription.id,
                items: [{
                  quantity: 1,
                  price: stripePrice.id,
                  id: this.userPlan.stripeSubscriptionItem.id,
                }],
              },
            }
          }

          // if they're downgrading, show a confirmation modal first...
          if (
            selectedPlan.metadata.productSlug === 'free'
            || (this.userPlan.slug === 'pro' && selectedPlan.metadata.productSlug === 'starter')
          ) {

            promise = new Promise((resolve, reject) => {
              this.$store.dispatch('modals/OPEN_MODAL', {
                name: 'PlanDowngradeConfirmationModal',
                onClose: resolve,
                props: {
                  selectedStripePrice: stripePrice,
                  action: () => {
                    return this.$store.state.api.dispatch
                      .post('/stripe/session/billing', billingPortalFormData)
                      .then(resolve)
                      .catch(reject)
                  },
                },
              })
            })

          // otherwise send them on over to stripe to change their subscription
          } else {
            promise = this.$store.state.api.dispatch.post('/stripe/session/billing', billingPortalFormData)
          }

        // no current subscription, so do a normal checkout session
        } else {

          mixpanelEventData.isFirstCheckout = true

          this.$store.commit('forms/SET_FIELD_VALUE', {
            fieldName: 'stripePriceId',
            formName: this.formName,
            newValue: stripePrice.id,
          })

          promise = this.$store.dispatch('forms/SUBMIT_FORM', this.formName)

        }

        return promise
          .then((response) => {

            this.$mixpanel.onReady((mixpanel) => {
              mixpanel.track('Plan Selected', mixpanelEventData)
            })

            // @NOTE: this check is here for when the downgrade confirmation
            //  modal is dismissed via cancel, in which case the promise will
            //  not be returning an api response (or anything)
            if (!response || !response.data || !response.data.url) {
              this.isLoading = false
              return
            }

            // @NOTE: we don't set isLoading to false here (or in a .finally()
            //  below) so the links stay disabled as the page navigates away to
            //  stripe
            window.location = response.data.url

          })
          .catch(() => {
            this.isLoading = false
            this.$store.dispatch('toast/CREATE_TOAST', {
              text: 'Could not select plan. Please try again later.',
              type: 'error',
            })
          })

      },
    },
  }

</script>

<style lang="stylus" scoped>

  h2
    @apply mb-8
    @apply text-center

  form
    @apply flex
    @apply flex-col
    @apply items-center

  p + p
    @apply mt-2

  .bordered
    @apply border
    @apply rounded-md
    @apply border-gray-400

  section
    @apply w-full

    &+section
      @apply mt-6

  .plans
    @apply flex
    @apply flex-col
    @apply items-center

  .pay-period-switcher
    @apply mb-6
    @apply w-auto
    @apply inline-block

  .plan-columns
    @apply w-full

    @apply flex
    @apply gap-2
    @apply flex-col

    +breakpoint(lg)
      @apply flex-row

  .plan-wrapper
    @apply w-full

    margin-top: 38px

    &.most-popular
      @apply mt-0
      @apply border-2
      @apply border-primary-500

      // account for the thicker border by bringing the white plan up a bit so
      // the buttons line up
      top: -3px
      @apply relative

      .most-popular-label
        @apply p-2
        @apply font-bold
        @apply text-white
        @apply bg-gradient-to-r from-primary-500 to-purple-500

        @apply flex
        @apply gap-2
        @apply items-center
        @apply justify-center

        svg
        img
          @apply w-4
          @apply h-4

  .plan
    @apply p-8

    .plan-image
      @apply w-32
      @apply h-32
      @apply mb-4

    h5
      @apply mb-2

    p
      @apply text-gray-600

    .plan-price
      @apply mt-6
      @apply font-bold
      @apply text-black

      .cost
        @apply text-4xl

      .pay-period
        @apply ml-1
        @apply text-sm

      .description
        @apply mt-1
        @apply text-sm
        @apply font-normal
        @apply text-gray-600

    button
      @apply py-2
      @apply px-4
      @apply mt-4
      @apply w-full

      .current-plan-icon
        margin-left: .375rem
        @apply text-success-500

      &:not(.outline)
        .current-plan-icon
          @apply text-success-300

    .plan-features
      @apply mt-6

      strong
        @apply text-lg

      ul
        @apply mt-2

  .pay-per-action
    @apply py-9
    @apply px-12

    @apply bg-gradient-to-b from-primary-100 to-purple-100

    a
      @apply underline

    .main-content
      @apply flex
      @apply gap-2
      @apply items-start

      .toggle
        @apply flex

      .copy
        @apply pr-8

        h5
          @apply my-2

  .enterprise
    @apply mt-6
    @apply py-9
    @apply px-12

    @apply flex
    @apply gap-6

    .image
      @apply flex-shrink-0

    .copy
      h5
        @apply mt-0
        @apply mb-2

      .muted
        @apply text-lg
        @apply text-gray-600

      button
        @apply mt-3

  .faqs
    h3
      @apply mt-0
      @apply mb-3
      @apply text-center

    .questions
      @apply mt-6
      @apply py-8
      @apply mx-8
      @apply px-16
      @apply rounded-md
      @apply text-center
      @apply bg-primary-100

      +breakpoint(lg)
        @apply mx-16

    p + p
      @apply mt-3

    pre
      @apply whitespace-normal

</style>
