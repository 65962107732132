<template>
  <div class="accordion-item" :class="{ 'is-open': isOpen }">
    <div class="title" @click="toggle()">
      <ChevronIcon />
      {{ title }}
    </div>
    <div ref="content" class="content">
      <div class="content-inner">
      <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>

  import ChevronIcon from '@/assets/icons/chevron.svg'

  export default {
    name: 'AccordionItem',
    emits: [
      'toggle',
      'opened',
      'closed',
    ],
    components: {
      ChevronIcon,
    },
    props: {
      title: {
        type: String,
        required: true,
      },
      accordionItemIndex: {
        type: Number,
        required: true,
      },
    },
    watch: {
      isOpen(newValue, oldValue) {
        this.$refs.content.style.height = newValue ? `${this.getContentHeight()}px` : '0px'
      },
    },
    data() {
      return {
        isOpen: false,
      }
    },
    methods: {
      // quickly show the content so we can measure it's final height, then hide
      //  it again before it's really visible
      //
      // @NOTE: we clone the node here instead of just doing the height
      //  calculation on the hidden node itself because doing the height swap
      //  directly makes the whole thing not work quite right (maybe because the
      //  animation triggers back to back really fast?)
      getContentHeight() {
        const $clone = this.$refs.content.cloneNode(true)
        $clone.style.height = 'unset'
        this.$refs.content.parentElement.appendChild($clone)
        const { offsetHeight } = $clone
        this.$refs.content.parentElement.removeChild($clone)
        return offsetHeight
      },
      close() {
        this.isOpen = false
      },
      toggle(newIsOpen = !this.isOpen) {
        this.isOpen = newIsOpen
        this.$emit(this.isOpen ? 'opened' : 'closed')
        this.$emit('toggle', { isOpen: this.isOpen, accordionItemIndex: this.accordionItemIndex })
      },
    },
  }

</script>

<style lang="stylus" scoped>

  .accordion-item
    @apply border-t
    @apply border-gray-400

    .title
      @apply px-6
      @apply py-4

      @apply text-xl
      @apply font-bold

      @apply flex

      &:hover
        @apply cursor-pointer
        @apply bg-purple-200

      svg
        @apply w-4
        @apply h-4
        @apply mr-2
        @apply text-primary-500

        @apply transform
        @apply -rotate-90
        @apply transition-transform

        margin-top: .375rem

    :deep(.subtitle)
      @apply pt-6
      @apply text-lg
      @apply font-bold
      @apply text-gray-600

    .content-inner
      @apply pt-2
      @apply pb-8
      @apply pl-12
      @apply pr-16

    .content
      @apply h-0
      @apply transition
      @apply overflow-hidden

      transition: height .4s ease-in-out

    &.is-open
      .title
        svg
          @apply rotate-0

</style>
